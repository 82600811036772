#root,
.App {
  min-width:100%;
  min-height: 100%;
}

.title {
  text-align: center;
  margin-bottom: 20px;
}

.demo-container {
  position: fixed;
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.column-left {
  width: 30%;
  display: flex;
  flex-direction: column;
  background: #f7fafc;
  border-right: 1px solid #2d3748;
}

.controls-container {
  display: flex;
  flex-direction: column;
  flex-basis: 1;
  overflow-y: scroll;
}

.state-container {
  display: none;
  flex-direction: column;
  flex-basis: 30%;
  border-top: 1px solid black;
}
.state-container > h4 {
  color: black;
}

.state {
  width: 100%;
  height: 100%;
}

.column-right {
  height: 100%;
  width: 100%;
}

.tree-container {
  height: 100%;
  /* background: #ccf6c8; */
}

.prop-container {
  padding: 2px 5px;
  margin: 15px 10px;
}
.prop {
  font-weight: bold;
  display: block;
}

.prop-large {
  font-size: 16px;
}

.sub-prop {
  padding: 5px;
}

.btn-controls {
  color: #f7fafc;
  background-color: #2d3748;
  transition: color 0.15s ease, border-color 0.15s ease;
}
  
.btn-controls:hover,
.btn-controls:focus,
.btn-controls:active {
  color: white;
  border-color: #f7fafc;
}

.tree-stats-container {
  text-align: center;
  padding: 0.5rem 2rem;
  border-bottom: 1px solid #2d3748;
  font-weight: bold;
}

/* Custom node classes */
.demo-node > rect {
  fill: #FFF;
  stroke: #2d3748;
}

.demo-node > rect.clicked {
  fill: #FFD41E;
  stroke: #2d3748;
}

/*.demo-node > rect.clicked + text {
  fill: #FFF!important;
}*/

.demo-node > rect.root {
  fill: #bababa;
  stroke: #2d3748;
}

.rd3t-leaf-node {
  fill: #FFF!important;
  stroke: #2d3748!important;
}

.rd3t-leaf-node > rect.clicked {
  fill: #FFD41E;
  stroke: #2d3748;
}

/* Custom path classes */
.my-path-class {
  stroke: royalblue;
  stroke-width: 10;
}

